<template>
  <div class="addOn-filterLabels-container">
    <div
      v-expose="{
        id: '1-8-4-26',
        data: {
          store_code: storeInfo.id
        }
      }"
      :class="[
        'store-label',
        'filter-label',
        isSelectedStore ? 'selected-label' : '',
      ]"
      @click="handleLabelClick(LabelKeyMap.STORE)"
    >
      <template v-if="isSelectedStore">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
          <rect x="0.65" y="-0.65" width="8.34286" height="4.76667" transform="matrix(1 0 0 -1 1.17871 10.525)" stroke="white" stroke-width="1.3"/>
          <path d="M0.815066 6.40838L1.80408 2.07505H10.1959L11.1849 6.40838H0.815066Z" stroke="white" stroke-width="1.3"/>
          <rect x="4.50718" y="2.07505" width="2.98571" height="4.33333" stroke="white" stroke-width="1.3"/>
        </svg>
      </template>
      <template v-else>
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
          <rect x="0.65" y="-0.65" width="8.34286" height="4.76667" transform="matrix(1 0 0 -1 1.17871 10.65)" stroke="#666666" stroke-width="1.3"/>
          <path d="M0.815066 6.53332L1.80408 2.19999H10.1959L11.1849 6.53332H0.815066Z" stroke="#666666" stroke-width="1.3"/>
          <rect x="4.50718" y="2.19999" width="2.98571" height="4.33333" stroke="#666666" stroke-width="1.3"/>
        </svg>
      </template>
      <span class="content">{{ storeLabelText }}</span>  
      <Icon
        v-if="isSelectedStore"
        class="close-icon"
        name="sui_icon_close_18px"
        size="12px"
      />
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineExpose, defineEmits, computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { LabelKeyMap } from 'public/src/pages/common/add-on/utils/constants.js'
import { template } from '@shein/common-function'

const props = defineProps({
  selectedFilterLabels: {
    type: Array,
    default: () => ([])
  },
  storeInfo: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  }
})
const emits = defineEmits(['change'])
const isSelectedStore = computed(() => {
  return props.selectedFilterLabels.includes(LabelKeyMap.STORE)
})

const storeLabelText = computed(() => {
  if(isSelectedStore.value) {
    return template(props.storeInfo.name, props.language.SHEIN_KEY_PWA_32732)
  } else {
    return template(props.storeInfo.name, props.language.SHEIN_KEY_PWA_32733)
  }
})

function handleLabelClick(label) {
  if(label) {
    if(props.selectedFilterLabels.includes(label)) {
      emits('change', 'cancel', label)
    } else {
      emits('change', 'check', label)
    }
  }
}

defineExpose({

})
</script>
<style lang="less" scoped>
.addOn-filterLabels-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 0 20/75rem 20/75rem;
}
.filter-label {
  cursor: pointer;
}
.store-label {
  text-align: center;
  position: relative;
  display: inline-block;
  padding: 18/75rem 68/75rem;
  border-radius: 60/75rem;
  background: #f6f6f6;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: normal;
  width: 100%;
  .content {
    margin-left: 12/75rem;
  }
  &>* {
    vertical-align: middle;
  }
}
.close-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20/75rem;
}
.selected-label {
  background-color: #000;
  color: #fff;
}
</style>
