export default function parabolaAnimate(eleId, startXY, endXY, callback) {
  // 确定起点和终点的坐标
  const startX = startXY.x
  const startY = startXY.y
  const endX = endXY.x
  const endY = endXY.y

  const moveX = (startX - endX) / 24

  // 确定抛物线的方程
  const a = (endY - startY) / Math.pow((endX - startX), 2)

  // 获取动画元素
  const element = document.getElementById(eleId)

  // 初始化当前的 x 值
  let currentX = startX

  // 创建动画函数
  function animate() {
    // 更新当前的 x 值
    currentX -= moveX

    // 计算当前的 y 值
    const currentY = a * Math.pow((currentX - startX), 2) + startY

    // 更新动画元素的位置
    element.style.left = currentX + 'px'
    element.style.top = currentY + 'px'

    // 如果当前的 x 值还没有达到终点的 x 值，那么继续动画
    if (currentX > endX) {
      requestAnimationFrame(animate)
    } else {
      callback?.()
    }
  }

  // 开始动画
  animate()
}
