<template>
  <div 
    class="line-progress"
    :style="{
      height: transformRem(height),
      'background-color': _bgColor,
    }"
  >
    <div
      class="line-progress-bg"
      :class="{
        'is-transparent': transparent,
      }"
      :style="{ 
        height: transformRem(height),
        width: `${value}%`,
        'background': _color,
      }"
    ></div>
  </div>
</template>

<script>

export default {
  props: {
    height: {
      type: [String, Number],
      default: 3
    },
    value: {
      type: [String, Number],
      default: 0
    },
    color: {
      type: String,
      default: '#222',
    },
    bgColor: {
      type: String,
      default: '#DDD'
    },
    transparent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    _color() {
      return this.color
    },
    _bgColor() {
      return this.bgColor
    }
  },
  methods: {
    transformRem(num) {
      return num * 2 / 75 + 'rem'
    }
  },
}
</script>

<style lang="less" scoped>
.line-progress {
  width: 100%;
  height: 12/75rem;
  background: #FFE0D8;
  border-radius: 10/75rem;
  .line-progress-bg {
    position: relative;
    height: 100%;
    border-radius: 10/75rem;
    background-size: 24/75rem 24/75rem !important; /* stylelint-disable-line declaration-no-important */
  }
  .is-transparent::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, .3));
  }
}
</style>
