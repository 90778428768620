class SideFilterUtils {
  constructor() {
    this.data = {}
  }

  use (itemKey, menuBar, menuItems) {
    this.data[itemKey] = {
      menuBars: Array.isArray(menuBar) ? menuBar : [menuBar],
      menuItems: Array.isArray(menuItems) ? menuItems : [menuItems]
    }
  }

  get(sorts = []) {
    const menuBarConfig = []
    const menuItemsConfig = []
    sorts.forEach((key) => {
      const { menuBars = [], menuItems = [] } = this.data[key] || {}
      menuBarConfig.push(...menuBars)
      menuItemsConfig.push(...menuItems)
    })
    return [menuBarConfig, menuItemsConfig]
  }
}

export default SideFilterUtils
