<template>
  <div v-if="navConfig && navConfig.length">
    <SFilter
      ref="filterEl"
      :nav-config="navConfig"
      @clickBar="clickBar"
      @onChange="filterChange"
    >
      <Panel 
        v-if="menuList.length"
        :animation="{ direction: 'down' }" 
      >
        <SelectMenu
          :default-select-index="curSortVal"
          :menu-list="menuList"
        />
      </Panel>
      <Panel 
        v-if="isCate"
        :animation="{ direction: 'down' }"
      >
        <SingleSelect
          :data="categories"
          :title="language.SHEIN_KEY_PWA_14980"
          type="tree"
          :language="language"
          :sum="total"
          :url-selected-id="queryParams.categorie"
        />
      </Panel>
      <Panel 
        v-if="onePitData"
        :animation="{ direction: 'down' }"
      >
        <MultiSelect
          :all-data="filterAttrs"
          :cur-data="filterAttrs[0]"
          :url-selected-id="curAttrIds"
          :sum="total"
          :data-type="'attr'"
          :language="language"
          :reset-data.sync="resetPitData"
        />
      </Panel>
      <Panel
        :animation="{ direction: 'rtl' }"
        display-mode="fullscreen"
      >
        <!-- @side-change="changeFilterShowState" -->
        <SideFilter
          :menu-bar-config="menuBarConfig"
          :menu-items-config="menuItemsConfig"
          :sum="total"
          :reset-data.sync="resetData"
          :language="language"
          :show-left-filter="showLeftFilter"
          left-echo-num
        />
      </Panel>
    </SFilter>
  </div>
</template>

<script>
import SFilter from 'public/src/pages/components/filter_new/Filter'
import Panel from 'public/src/pages/components/filter_new/components/Panel'
import SelectMenu from 'public/src/pages/components/filter_new/components/SelectMenu'
import SingleSelect from 'public/src/pages/components/filter_new/components/SingleSelect'
import MultiSelect from 'public/src/pages/components/filter_new/components/MultiSelect'
import SideFilter from 'public/src/pages/components/filter_new/components/SideFilter'
import { formatMuitlData } from 'public/src/pages/components/filter_new/common/format'

import SideFilterUtils from 'public/src/pages/user/child_pages/wishlist_v2/components/Filter/SideFilterUtils'
import { cloneDeep } from 'lodash'
const NAV_ICON = { normal: 'sui_icon_more_down_12px_2', active: 'sui_icon_more_up_12px_2', isSuiIcon: true }
const FILTER_ICON = { normal: 'sui_icon_filter_16px', isSuiIcon: true }
const SIDE_FILTER_SORTS = ['cate', 'attrs', 'price']

const { currency, GB_cssRight } = gbCommonInfo
const getFilterSort = (language = {}) => {
  return [
    {
      label: language.SHEIN_KEY_PWA_15647,
      label_en: language.SHEIN_KEY_PWA_15647,
      value: 0,
    },
    {
      label: language.SHEIN_KEY_PWA_17370,
      label_en: language.SHEIN_KEY_PWA_17370,
      value: 12,
    },
    {
      label: language.SHEIN_KEY_PWA_15557,
      label_en: language.SHEIN_KEY_PWA_15557,
      value: 11
    },
    {
      label: language.SHEIN_KEY_PWA_15645,
      label_en: language.SHEIN_KEY_PWA_15645,
      value: 9,
    },
    {
      label: language.SHEIN_KEY_PWA_15646,
      label_en: language.SHEIN_KEY_PWA_15646,
      value: 10,
    },
  ]
}
export default {
  components: {
    SFilter,
    Panel,
    SelectMenu,
    SingleSelect,
    MultiSelect,
    SideFilter,
  },
  props: {
    /**
     * categories Array
     * filterAttrs Array
     * maxPrice Number
     * minPrice Number
    */
    filterData: {
      type: Object,
      default: () => ({})
    },
    queryParams: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    total: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      showLeftFilter: true, // 右滑Filter抽屉的类型

      categories: { data: [] },
      filterAttrs: [],
      menuBarConfig: [],
      menuItemsConfig: [],
      resetPitData: false,
      resetData: false,  // 筛选重置

      sideFilterUtils: {},
      activeIndex: 0,
    }
  },
  computed: {
    menuList() {
      return getFilterSort(this.language)
    },
    navConfig() {
      const navs = [{ text: this.language.SHEIN_KEY_PWA_15500, icons: NAV_ICON, panelContentSelected: true }]
      if (this.isCate) {
        navs.push({ text: this.language.SHEIN_KEY_PWA_14980, icons: NAV_ICON })
      }
      if (this.onePitData) {
        navs.push(this.onePitData)
        navs.push({ 
          text: this.language.SHEIN_KEY_PWA_15044, 
          icons: FILTER_ICON, 
          hasSeperator: true, 
          hasCountBadge: true, 
          styles: { [GB_cssRight ? 'paddingLeft' : 'paddingRight']: '18px' } 
        })
      }
      return navs
    },
    onePitData() {
      if (!this.filterAttrs.length) return
      return { text: this.filterAttrs[0]?.name, icons: NAV_ICON } // 取第一个进行展示
    },
    isCate() {
      return this.categories.data.length > 0
    },
    curSortVal() {
      const index = this.menuList.findIndex(({ value }) => this.queryParams.sort == value)
      return index > -1 ? index : 0
    },
    curAttrIds() {
      return []
    },
  },
  watch: {
    filterData: {
      immediate: true,
      handler() {
        this.initData()
      }
    }
  },
  methods: {
    initData() {
      this.sideFilterUtils = new SideFilterUtils()
      const {  categories = [], attrs = [], minPrice, maxPrice } = this.filterData
      this.handleCate(categories)
      this.handleAttrs(attrs)
      this.handlePriceRange(minPrice, maxPrice)
      this.handleFilterSide() // 必须放到最后处理

      this.$nextTick(() => {
        this.$refs.filterEl?.initPanelData()
      })
    },
    handleCate(categories) {
      if (!categories || !categories.length) return
      this.categories = {
        curValue: this.queryParams.categorie || '',
        data: categories,
      }

      this.sideFilterUtils.use(SIDE_FILTER_SORTS[0], {
        text: this.language.SHEIN_KEY_PWA_14980,
        link: true,
        active: true
      }, {
        type: 'single',
        dataType: 'cate',
        collapse: 2,
        data: this.categories
      })
    },
    handleAttrs(attrs) {
      if (attrs && !attrs.length) return
      const formatAttrs = cloneDeep(attrs).map(({ attr_id, attr_name, attr_name_en, attr_values }) => {
        const data = attr_values.map(v => {
          v.attr_filter = `${v.attr_id}_${v.attr_value_id}`  // attr_values下加入attr_filter字段
          return v
        })
        return {
          data, 
          attr_id, 
          attr_name, 
          attr_name_en
        }
      })

      this.filterAttrs = formatMuitlData({
        attr_id: 'id',
        attr_name: 'name',
        attr_name_en: 'name_en',
        data: 'data',
        dataItem: {
          attr_filter: 'mix_value',
          attr_value: 'label',
          attr_value_en: 'label_en',
          attr_value_id: 'value',
          group_name: 'label',
          group_name_en: 'label_en',
          group_image: 'label_img'
        }
      }, formatAttrs)

      // 侧边filter
      const attrsMenuBarConfig = []
      const attrsMenuItemsConfig = []
      const activeAttrs = {} // 回显数据
      this.curAttrIds.forEach(item => {
        const [id] = item.split('_')
        if (activeAttrs[id]) {
          activeAttrs[id].push(item)
        } else {
          activeAttrs[id] = [item]
        }
      })

      this.filterAttrs.forEach((attr, index) => {
        // const active = !!activeAttrs[attr.id] || index < 1
        // const active = true
        attrsMenuBarConfig.push({
          text: attr.name,
          count: true,
          active: true,
        })
        attrsMenuItemsConfig.push({
          type: attr.show_group ? 'img' : 'multi',
          collapse: index < 1 ? 2 : 0,
          data: attr,
          selectedMulti: activeAttrs[attr.id] || [],
        })
      })
      this.sideFilterUtils.use(SIDE_FILTER_SORTS[1], attrsMenuBarConfig, attrsMenuItemsConfig)
    },
    handlePriceRange (min_price, max_price) {
      if (!max_price) return 
      let data = { min_price, max_price }
      const { maxPrice: startMaxPrice, minPrice: startMinPrice } = this.queryParams
      
      if (startMaxPrice && startMinPrice) { // 回显数据
        data = {
          ...data,
          startMaxPrice,
          startMinPrice
        }
      }

      this.sideFilterUtils.use(SIDE_FILTER_SORTS[2], {
        text: `${this.language.SHEIN_KEY_PWA_16527}(${currency})`,
        active: true,
        normal: true
      }, {
        type: 'price',
        data
      })
    },

    handleFilterSide() {
      const [menuBarConfig, menuItemsConfig] = this.sideFilterUtils.get(SIDE_FILTER_SORTS)
      this.menuBarConfig = menuBarConfig
      this.menuItemsConfig = menuItemsConfig
    },

    // 展开时触发
    clickBar(data) {
      this.activeIndex = data?.activeIndex
      this.$emit('onFilterBar', data)
    },
    // 点击面板内容时触发
    filterChange(params = {}) {
      const { data = {}, operation } = params
      
      let queryParams = {}

      let filterSort = ['sort']
      if (this.isCate) { filterSort.push('cate') }
      if (this.onePitData) { filterSort.push('attrs') }
      filterSort.push('sideFilter')

      const clickKey = filterSort[this.activeIndex] && `change${filterSort[this.activeIndex]}`
      if (clickKey) {
        queryParams = this[clickKey](data, params) // 拼接执行方法 changesort 等等...    
      }

      this.$emit('filterChange', queryParams, operation === 'resetAll')
    },

    changesort({ value }) {
      return { sort: value }
    },
    changecate({ value }) {
      return { filter_cate_id: value }
    },
    changeattrs(data) {
      const attrIds = []
      const attrValues = []
      data.forEach(item => {
        attrIds.push(item.mix_value)
        attrValues.push(item.label.replace('-', ''))
      })
      const attributeList = attrIds.join('-')
      return { attr_filter: attributeList }
    },
    changesideFilter(data, obj) {
      const { type, dataType } = obj
      let params = {}
      if (type === 'single' && ['cate'].includes(dataType)) {
        params = this[`change${dataType}`](data, obj)
      } else if (['multi', 'img'].includes(type)) {
        params = this.changeattrs(data, obj)
      } else if (type === 'price') {
        params = this.changeprice(data, obj)
      }
      return params
    },
    changeprice(data, { startMinPrice, startMaxPrice }) {
      return { min_price: startMinPrice, max_price:	startMaxPrice }
    },



    // 外部调用
    resetFilter() {
      this.resetData = true
      this.resetPitData = true
      this.hideFilter()
    },
    hideFilter() {
      this.$refs?.filterEl?.hideAllPanel()
    },
  },
}
</script>
