import { plus } from '@shein/common-function'

export function fotmatCategories(cateList = []) {
  if (!Array.isArray(cateList) || !cateList.length) return []
  let list = []
  cateList.forEach(({ cat_id, cat_name, children } = {}) => {
    if (cat_id && cat_name) {
      list.push({
        label: cat_name,
        value: cat_id,
        children: fotmatCategories(children),
      })
    }
  })
  return list
}

/**
 * 获取加车商品的页面来源（add_source）
 */
export function getAddSourceByType(type, saInfo) {
  let _type = ''
  switch (type) {
    case 'coupon':
      _type = 'coupon' // 优惠券
      break
    case 'freeShipping':
      _type = 'shipping' // 免邮活动
      break
    default: // 其他值都归为促销活动
      _type = 'promotion' // 促销活动
      break
  }
  return `cartadd\`${_type}\`${saInfo?.activity_from || 'cartadd'}`
}

export const getRangeStatus = (cur, pre) => {
  const curProgress = plus(...cur?.map(v => v?.progressPercent)) || 0
  const preProgress = plus(...pre?.map(v => v?.progressPercent)) || 0
  let isRangeUp
  const preIndex = pre?.findIndex(v => v.progressPercent < 1)
  const curIndex = cur?.findIndex(v => v.progressPercent < 1)
  if (preIndex === -1) isRangeUp = false
  else if (curIndex === -1) isRangeUp = true
  else isRangeUp = curIndex > preIndex

  return {
    isUp: curProgress > preProgress,
    isDown: curProgress < preProgress,
    isChange: curProgress !== preProgress,
    isRangeUp,
  }
}

export const getTypeStatus = (typeId, logoType) => {
  return {
    isReturnCoupon: [20, 21].includes(+typeId),
    isXMoneyNItem: typeId == 15,
    isFullReduce: typeId == 14,
    isDiscount: [1, 9, 25, 26].includes(+typeId),
    isAccessory: [4, 2, 28, 22, 13].includes(+typeId),
    isFreeGift: [4, 2, 28].includes(+typeId),
    isSheinClubGift: typeId == 4 && [8, 14].includes(+logoType)
  }
}

export const routerPush = (path, type) => {
  if (!path) return
  if (type === 'router' && _gb_app_?.$routerPush) {
    _gb_app_.$routerPush(path)
  } else {
    window.location.href = path
  }
}
