<template>
  <div>
    <ClientOnly>
      <!-- 下拉展开 -->
      <s-slide
        v-if="!isFullScreen"
        ref="slide"
        :visible.sync="show"
        :modal="true"
        :close-on-click-modal="!loading"
        :modal-position="true"
        @close-from-mask="clickOverlay"
      >
        <!-- 自定义panel内容 -->
        <slot></slot>
      </s-slide>

      <!-- 侧边抽屉 -->
      <s-drawer
        v-else
        ref="drawerFilter"
        :visible.sync="show"
        :direction="animation.direction"
        :append-to-body="true"
        :immediately-render="true"
        max-size="88.4%"
        @close-from-mask="clickOverlay"
      >
        <!-- 自定义panel内容 -->
        <slot></slot>
      </s-drawer>
    </ClientOnly>
  </div>
</template>

<script>
import Vue from 'vue'
import ClientOnly from 'vue-client-only'
import { Slide } from '@shein/sui-mobile'
import { emitEvent } from '../utils'
import { scrollFix } from 'public/src/pages/common/utils/index.js'

Vue.use(Slide)

export default {
  name: 'FilterPanel',
  components: { ClientOnly },
  props: {
    /**
     * fullscreen 全屏panel
     */
    displayMode: {
      type: String,
      default: ''
    },
    /**
     * drawer的动画方向
     *  direction rtl/ltr/ttb/btt
     */
    animation: {
      type: Object,
      default: () => ({})
    },

    // 抽屉内有filter时，传递true 解决多次展开层级问题。
    showNextTick: Boolean, 
    item: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      show: false,
      position: 0,
      scrollFixInstance: null
    }
  },
  computed: {
    isFullScreen () {
      return this.displayMode == 'fullscreen'
    },
  },
  watch: {
    show: {
      handler () {
        // TODO 组件库slide调用，后续需要优化
        this.scrollFixInstance &&  this.$nextTick(() => this.scrollFixInstance(this.show))
        if (this.show && !this.isFullScreen) {
          window.vBus && window.vBus.$off('onBranchHide')
          window.vBus &&
            window.vBus.$on('onBranchHide', () => {
              this.updateSlideTop()
            })
          appEventCenter.$off('updatePanelTop')
          appEventCenter.$on('updatePanelTop', () => {
            this.updateSlideTop()
          })
        }
        if (this.isFullScreen)
          emitEvent(this.$parent, 'showSideFilter', this.show)
        else emitEvent(this.$parent, 'showSlide', this.show)
      },
      immediate: true
    }
  },
  mounted () {
    this.scrollFixInstance = new scrollFix()
    this.initEvent()
  },
  beforeDestroy () {
    if (this.show) {
      this.scrollFixInstance(false)
      if (typeof window === 'undefined' || !window.$) return
      $('body').removeClass('S-popup-parent__hidden')
    }
  },
  methods: {
    // 初始化一些监听事件
    initEvent () {
      this.$on('clickMenuItem', this.clickMenuItem)
      this.$on('singleSelectChange', this.singleSelectChange)
      this.$on('multiSelectChange', this.multiSelectChange)
      this.$on('clickDone', this.clickDone)
      this.$on('closeSidePanel', this.closeSidePanel)
      this.$on('sidePanelChange', this.sidePanelChange)
      this.$on('resetAll', this.resetAll)
      this.$on('recordSelectMenu', this.recordSelectMenu)
      this.$on('needHighlightNavBar', this.needHighlightNavBar)
      this.$on('rangeChange', this.rangeChange)
      // this.$on('priceSlideChange', this.priceSlideChange)
    },
    /**
     * 点击select menu
     * item: 点击项
     * active: 当前的是否已经被选中，true已选中，false未选中
     * from: 触发方式
     */
    clickMenuItem ({ payload, active, from }) {
      this.close()
      emitEvent(this.$parent, 'clickMenuItem', { payload, index: this.position, active, from, item: this.item })
    },
    // 记录selectMenu位置
    recordSelectMenu () {
      emitEvent(this.$parent, 'recordSelectMenu', this.position)
    },
    needHighlightNavBar (payload) {
      emitEvent(this.$parent, 'needHighlightNavBar', { item: this.item, ...payload, index: this.position })
    },
    singleSelectChange (payload) {
      emitEvent(this.$parent, 'singleSelectChange', { item: this.item, ...payload, index: this.position })
    },
    multiSelectChange (payload) {
      emitEvent(this.$parent, 'multiSelectChange', { item: this.item, ...payload, index: this.position })
    },
    rangeChange (payload) {
      emitEvent(this.$parent, 'rangeChange', { item: this.item, ...payload, index: this.position })
    },
    clickDone () {
      this.close()
      emitEvent(this.$parent, 'clickDone')
    },
    toggle () {
      const fn = () => {
        this.show = !this.show
        this.sendShowStatusToSide(this.show)
        this.$emit('show', this.show)
      }
      // 加nextTick 解决抽屉中filter层级问题
      if (this.showNextTick) {
        this.$nextTick(fn)  
      } else {
        fn()
      }
    },
    close () {
      this.show = false
      this.$emit('show', false)
      this.sendShowStatusToSide(this.show)
    },
    // 点击遮罩层
    clickOverlay () {
      if (this.loading) return
      this.close()
      emitEvent(this.$parent, 'clickOverlay')
    },
    closeSidePanel () {
      this.close()
      emitEvent(this.$parent, 'clickSideFilterCloseIcon')
    },
    // 保存每一个panel的位置, 让panel和navBar可以联动起来
    recordIndex (index) {
      this.position = index
    },
    sidePanelChange (payload) {
      emitEvent(this.$parent, 'sidePanelChange', { item: this.item, ...payload, index: this.position })
    },
    resetAll (payload) {
      emitEvent(this.$parent, 'resetAll', { item: this.item, ...payload, index: this.position })
    },
    // panel展开收起的状态，传给内部slot组件使用，目前只有sideFilter有用
    sendShowStatusToSide (show) {
      if (this.$slots?.default?.[0]?.componentInstance?.setPanelShowStatus) {
        this.$slots.default[0].componentInstance.setPanelShowStatus(show)
      }
    },
    updateSlideTop() {
      if (this.show && this.$refs.slide) {
        this.$refs.slide.calWithModal()
      }
    },
    calHeight(){
      this.show && this.$refs.slide?.calHeight()
    }
  }
}
</script>
