import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {
      listEle: null,
      dragging: false,
      startY: 0,
    }
  },
  computed: {
    ...mapState(['isFullScreen', 'addSize']),
    maxSize() {
      return parseFloat(this.drawerConfig.maxSize)
    },
    diffSize() {
      return 100 - this.maxSize
    }
  },
  beforeDestroy() {
    this.unregister()
  },
  methods: {
    ...mapMutations(['setIsFullScreen', 'setAddSize']),
    getTouchStatus() {
      if (!this.listEle) this.listEle = document.querySelector('.drawer-content__list')
      return !this.listEle.scrollTop
    },
    requestAnimationFrame(start, end, time, callback) {
      const diff = (end - start) / time
      const animate = () => {
        let addSize = this.addSize + diff
        if (
          diff > 0 && addSize > end ||
          diff < 0 && addSize < end
        ) {
          addSize = end
        }
        this.setAddSize(addSize)

        if (
          diff > 0 && addSize < end ||
          diff < 0 && addSize > end
        ) {
          requestAnimationFrame(animate)
        } else {
          callback?.()
        }
      }
      animate()
    },
    onTouchstart(e) {
      if (!this.getTouchStatus()) return

      this.dragging = true

      const touch = e.touches[0]
      let deltaY = touch.clientY
      this.startY = deltaY
    },
    onTouchmove(e) {
      if (!this.getTouchStatus()) return

      // 禁止子元素的滚动行为
      if (
        !this.isFullScreen ||
        this.addSize > 0 && this.addSize !== this.diffSize ||
        this.addSize === 0 && this.isFullScreen
      ) {
        e.preventDefault()
      }

      const touch = e.touches[0]
      const deltaY =  this.startY - touch.clientY
      const moveSize = deltaY / (window.innerHeight / 100)
      if (
        this.isFullScreen && moveSize > 0 ||
        !this.isFullScreen && moveSize < 0
      ) return

      let addSize
      if (this.isFullScreen) {
        if (moveSize < -this.diffSize) {
          addSize = 0
        } else {
          addSize = 100 - this.maxSize + moveSize
        }
      } else {
        if (moveSize > this.diffSize) {
          addSize = this.diffSize
        } else {
          addSize = moveSize
        }
      }

      this.setAddSize(addSize)
    },
    onTouchend() {
      this.dragging = false

      if (this.diffSize / 2 < this.addSize) {
        this.requestAnimationFrame(this.addSize, this.diffSize, 12, () => {
          this.setIsFullScreen(true)
        })
      } else {
        this.requestAnimationFrame(this.addSize, 0, 12, () => {
          this.setIsFullScreen(false)
        })
      }
    },
    register() {
      this.$el.querySelector('.drawer-content__list')?.addEventListener?.('touchstart', this.onTouchstart)
      this.$el.querySelector('.drawer-content__list')?.addEventListener?.('touchmove', this.onTouchmove)
      this.$el.querySelector('.drawer-content__list')?.addEventListener?.('touchend', this.onTouchend)
    },
    unregister() {
      this.$el.querySelector('.drawer-content__list')?.removeEventListener?.('touchstart', this.onTouchstart)
      this.$el.querySelector('.drawer-content__list')?.removeEventListener?.('touchmove', this.onTouchmove)
      this.$el.querySelector('.drawer-content__list')?.removeEventListener?.('touchend', this.onTouchend)
    },
  }
}
