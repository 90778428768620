<template>
  <div 
    class="coupon-normal-head"
    :class="{ 'page': page }"
  >
    <div
      v-if="page"
      class="back"
    >
      <i
        class="icon sui_icon_nav_back_24px suiiconfont"
        @click="gotoBack"
      ></i>
    </div>
    <div class="header-content">
      <div class="left">
        <p class="title">
          {{ couponItem.couponTip }}
        </p>
        <p
          v-if="timeStamp > 0"
          class="countdown"
        >
          <span class="ends-in">{{ endInText }}</span>

          <CountDown
            :time-stamp="timeStamp"
          />
        </p>
      </div>
      <div class="right">
        <CouponFace
          :coupon-title="couponItem.couponTitle"
          :threshold="couponItem.couponThreshold"
          :up-label="couponItem.couponLabel"
          :is-ship-coupon="isShipCoupon"
          :font-style="{ 'font-size': couponFontSize }"
        />
      </div>
    </div>

    <CloseIcon
      v-if="!page"
      class="close-icon"
      size="14px"
      color="#fff"
    />
  </div>
</template>

<script>
import { EventPageBack } from 'public/src/pages/common/add-on/utils/event.js'

export default {
  name: 'CouponNormalHead',
  components: {
    CountDown: () => import(/* webpackChunkName: "add_on_v2_item" */'public/src/pages/common/add-on/comps/base/CountDown.vue'),
    CouponFace: () => import(/* webpackChunkName: "add_on_v2_item" */'./CouponFace.vue'),
    CloseIcon: () => import(/* webpackChunkName: "add_on_v2_item" */'public/src/pages/common/add-on/comps/base/CloseIcon.vue'),
  },
  props: {
    page: Boolean,
    couponItem: {
      type: Object,
      default: () => ({}),
    },
    couponInfo: {
      type: Object,
      default: () => ({}),
    },
    language: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    timeStamp() {
      return +this.couponItem?.endTimestamp
    },
    endInText() {
      return this.language.SHEIN_KEY_PWA_26614?.replace('{0}', '')
    },
    couponFontSize() {
      return ['5', '6', '9'].includes(this.couponItem?.apply_for) ? '12px' : '16px'
    },
    isShipCoupon() {
      return this.couponItem?.couponDiscountType == 0
    },
  },
  methods: {
    gotoBack() {
      EventPageBack.notify()
    }
  },
}
</script>

<style lang="less" scoped>
.coupon-normal-head {
  height: 102/37.5rem;
  overflow: hidden;
  position: relative;
  background-size: cover;
  border-radius: 12/37.5rem 12/37.5rem 0 0;
  background-image: var(--header-background-image, url('/pwa_dist/images/add-on/coupon-addon-header-bg_dialog-1c741c3569.png'));
  &.page {
    height: 118/37.5rem;
    background-size: 100% auto;
    background-position: top;
    border-radius: 0;
    background-image: var(--header-background-image-page, url('/pwa_dist/images/add-on/coupon-addon-header-bg_page-0c8cac2a25.png'));
    .back {
      height: 16/37.5rem;
      .icon { /* stylelint-disable-line selector-max-specificity */
        position: absolute;
        top: 5/37.5rem;
        left: 12/37.5rem;
        z-index: 1;
        line-height: 1;
        font-size: 24/37.5rem;
        color: #fff;
      }
    }
    .header-content {
      .left { /* stylelint-disable-line selector-max-specificity */
        .title { /* stylelint-disable-line selector-max-specificity */
          position: relative;
          font-size: 18px;
          color: #fff;
        }
      }
    }
  }
  .close-icon {
    position: absolute;
    top: 4/37.5rem;
    right: 8/37.5rem;
  }
}
.header-content {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 102/37.5rem;
  direction: ltr;
  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    height: 100%; 
    padding: 18/37.5rem 0rem 18/37.5rem 20/37.5rem;
    .title {
      font-size: 16px;
      line-height: 19/37.5rem;
      font-weight: bold;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      color: #fff;
    }
    .countdown {
      margin-top: 10/37.5rem;
      display: flex;
      align-items: center;
    }
    .ends-in {
      margin-right: 5/37.5rem;
    }
  }
  .right {
    height: 100%;
    flex-shrink: 0;
    margin-right: 16/37.5rem;
  }
}
</style>
