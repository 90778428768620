import schttp from 'public/src/services/schttp'

export class PitProduct {
  constructor({ type } ) {
    this.initialized = false

    this.data = null
    this.type = type // SINGLE_CARD | MULTI_CARD
    // 插坑商品
    this.list = []
    // 剩余插坑商品
    this.remainingPitProduct = []
    // 已经插坑商品
    this.alreadyPitProduct = []
  }

  getRealType() {
    switch (this.type) {
      case 'MULTI_CARD':
        return this.data?.addItemList?.length ?? 0 < 4 ? 'SINGLE_CARD' : 'MULTI_CARD'
      default:
        return this.type
    }
  }

  async initialize(data) {
    if (this.initialized) return

    this.data = data
    await this.appendProductCardData(data)
    const _list = this.handlePitProduct(data.addItemList)
    this.list = _list
    this.remainingPitProduct = [..._list]
    this.alreadyPitProduct = []

    this.initialized = true

    if (typeof window !== 'undefined') {
      window.__pit_product__ = this
    }
  }

  // 停止插坑
  stop() {
    this.disabled = true
  }

  getProductsByPit(products) {
    if (!this.initialized) return products
    if (this.disabled) return products
    if (products?.length < 2) return products
    if (!this.remainingPitProduct.length) return products

    switch (this.getRealType()) {
      case 'SINGLE_CARD':
        return this.getProductsByPitBySingleCard(products)
      case 'MULTI_CARD':
        return this.getProductsByPitByMulitCard(products)
      default:
        return products
    }
  }

  getProductsByPitBySingleCard(products) {
    return insertWithStep(products, this.remainingPitProduct, 2, 5, {
      // 插坑商品插入后的回调
      afterPit: () => {
        this.alreadyPitProduct.push(this.remainingPitProduct.shift())
      }
    })
  }

  getProductsByPitByMulitCard(products) {
    const resultArray = [...products]
    const insertArray = this.remainingPitProduct.slice(0, 4)
    resultArray.splice(3, 0, {
      __cart_item_data__: true,
      multiCardTopIcon: this.data.multiCardTopIcon,
      multiCardTopTip: this.data.multiCardTopTip,
      items: insertArray,
    })

    this.alreadyPitProduct = insertArray
    this.remainingPitProduct = []

    return resultArray
  }

  handlePitProduct(list) {
    if (!list?.length) return []
    return list.map((item) => {
      return {
        __cart_item_data__: true,
        ...item,
      }
    })
  }

  async appendProductCardData(data) {
    if (this.getRealType() !== 'SINGLE_CARD') return data
    try {
      const { currency, appLanguage } = window.gbCommonInfo
      const goodsInfos = data.addItemList.map(item => ({
        goodsId: item.goodsId,
        goodsSn: item.goodsSn,
        mallCode: item.mallCode,
      }))
      const res = await schttp({
        method: 'POST',
        url: '/category-api/shopping-cart/get-slotting-products',
        data: {
          goodsInfos,
        },
        headers: {
          'AppCurrency': currency,
          'AppLanguage': appLanguage,
          'frontend-scene': 'page_web_common'
        },
        useBffApi: true
      })
      const code = res?.code
      const products = res?.info?.products || []
      if (code == '0' && products.length) {
        data.addItemList = data.addItemList.map(item => {
          return {
            ...item,
            productCardData: products.find(card => card.goods_id == item.goodsId && card.goods_sn == item.goodsSn),
          }
        }).filter(item => !!item.productCardData)
      } else {
        // eslint-disable-next-line require-atomic-updates
        data.addItemList = []
      }
    } catch (e) {
      // eslint-disable-next-line require-atomic-updates
      data.addItemList = []
    }
    return data
  }
}

function insertWithStep(originalArray, insertArray, startIndex = 0, step = 1, hooks) {
  if (!Array.isArray(originalArray) || !Array.isArray(insertArray) || startIndex < 0 || step <= 0) {
    return originalArray // 处理无效输入
  }

  const resultArray = [...originalArray]
  const _insertArray = [...insertArray]
  let insertIndex = startIndex

  for (let i = 0; i < _insertArray.length; i++) {
    if (insertIndex > resultArray.length) {
      break
    }
    resultArray.splice(insertIndex, 0, _insertArray[i])
    insertIndex += step + 1 // 更新插入位置，注意加上 1 才是步数
    hooks?.afterPit?.(_insertArray[i])
  }

  return resultArray
}
