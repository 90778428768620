var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"addOn-filterLabels-container"},[_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
      id: '1-8-4-26',
      data: {
        store_code: _vm.storeInfo.id
      }
    }),expression:"{\n      id: '1-8-4-26',\n      data: {\n        store_code: storeInfo.id\n      }\n    }"}],class:[
      'store-label',
      'filter-label',
      _setup.isSelectedStore ? 'selected-label' : '',
    ],on:{"click":function($event){return _setup.handleLabelClick(_setup.LabelKeyMap.STORE)}}},[(_setup.isSelectedStore)?[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"12","height":"13","viewBox":"0 0 12 13","fill":"none"}},[_c('rect',{attrs:{"x":"0.65","y":"-0.65","width":"8.34286","height":"4.76667","transform":"matrix(1 0 0 -1 1.17871 10.525)","stroke":"white","stroke-width":"1.3"}}),_vm._v(" "),_c('path',{attrs:{"d":"M0.815066 6.40838L1.80408 2.07505H10.1959L11.1849 6.40838H0.815066Z","stroke":"white","stroke-width":"1.3"}}),_vm._v(" "),_c('rect',{attrs:{"x":"4.50718","y":"2.07505","width":"2.98571","height":"4.33333","stroke":"white","stroke-width":"1.3"}})])]:[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"12","height":"13","viewBox":"0 0 12 13","fill":"none"}},[_c('rect',{attrs:{"x":"0.65","y":"-0.65","width":"8.34286","height":"4.76667","transform":"matrix(1 0 0 -1 1.17871 10.65)","stroke":"#666666","stroke-width":"1.3"}}),_vm._v(" "),_c('path',{attrs:{"d":"M0.815066 6.53332L1.80408 2.19999H10.1959L11.1849 6.53332H0.815066Z","stroke":"#666666","stroke-width":"1.3"}}),_vm._v(" "),_c('rect',{attrs:{"x":"4.50718","y":"2.19999","width":"2.98571","height":"4.33333","stroke":"#666666","stroke-width":"1.3"}})])],_vm._v(" "),_c('span',{staticClass:"content"},[_vm._v(_vm._s(_setup.storeLabelText))]),_vm._v(" "),(_setup.isSelectedStore)?_c(_setup.Icon,{staticClass:"close-icon",attrs:{"name":"sui_icon_close_18px","size":"12px"}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }