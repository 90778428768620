var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line-progress",style:({
    height: _vm.transformRem(_vm.height),
    'background-color': _vm._bgColor,
  })},[_c('div',{staticClass:"line-progress-bg",class:{
      'is-transparent': _vm.transparent,
    },style:({ 
      height: _vm.transformRem(_vm.height),
      width: `${_vm.value}%`,
      'background': _vm._color,
    })})])
}
var staticRenderFns = []

export { render, staticRenderFns }