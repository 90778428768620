<template>
  <DrawerContent>
    <template v-if="isOnline || products.length">
      <template slot="header">
        <slot name="header"></slot>
      </template>
      <template slot="filter">
        <FilterBar 
          ref="filter"
          :class="[
            showFilterLabelsChecked || showFilterLabelsUnchecked ? 'filter-with-store' : '',
          ]"
          :filter-data="filterData"
          :query-params="queryParams"
          :total="total"
          :language="language"
          @onFilterBar="onFilterBar"
          @filterChange="filterChange"
        />
        <FilterLabels
          v-if="showFilterLabelsChecked"
          :selected-filter-labels="selectedFilterLabels"
          :store-info="storeInfo"
          :language="language"
          @change="handleFilterChange"
        />
      </template>

      <template slot="list">
        <FilterLabels
          v-if="showFilterLabelsUnchecked"
          :selected-filter-labels="selectedFilterLabels"
          :store-info="storeInfo"
          :language="language"
          @change="handleFilterChange"
        />
        <div
          class="icon-flashsale-wrapper j-icon-flashsale-wrapper"
          :style="flashsaleIconStyle"
        ></div>
        <ProductListV3
          ref="ProductList"
          :products="products"
          :language="language"
          :sa="sa"
          :load-more-flag="loadMoreFlag"
          :loading="loading"
          :itemConfigV3="itemConfigV3"
          @load-more="loadMore"
          @reset="handleResetList"
          @add-bag="handleAddtobag"
          @update-bag="handleUpdateBag"
          @click-pit-item="handleClickPitItem"
          @click-pit-card="handleClickPitCard"
        />
        <ResetAllFilter
          v-show="showRestAll"
          v-tap="{ id: '1-8-4-29'}"
          :language="language"
          @click.native="handleResetFilter"
        />
      </template>

      <template slot="footer">
        <FooterTips ref="footerTips" />
        <slot name="footer">
        </slot>
      </template>
    </template>
    <template slot="list">
      <NoNetworkEmpty
        :is-empty="products.length == 0"
        :language="language"
        @online-changed="handleOnlineStatus"
      />
    </template>
  </DrawerContent>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { template, htmlDecode, stringifyQueryString } from '@shein/common-function'
import Content from '../comps/Content.vue'
import FooterTips from '../comps/FooterTips.vue'
import FilterBar from '../comps/FilterBar.vue'
import ListV3 from '../comps/List_v3.vue'
import { fotmatCategories, getAddSourceByType, routerPush } from '../utils/index.js'
import { getListsV3, getInterfaceListV3, cartUpdateAttr } from '../utils/fetcher.js'
import { SA_INFO, ADD_ON_COMP_MAP, LabelKeyMap } from '../utils/constants.js'
import {
  EventExposeAddCart,
  EventPageAnalysis,
  EventUpdateCart, 
  EventListReset,
  EventProgressUpdate,
  EventSetProgressPromise,
  EventUpdateIncentivePoint,
  EventViewTap,
  EventClose,
} from '../utils/event.js'
import parabolaAnimate from '../utils/parabolaAnimate.js'
import PromiseActuator from '../utils/PromiseActuator.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import fullScreenMixin from '../mixins/fullScreen.js'
import { modifyCartCheckStatus } from 'public/src/pages/product_app/store/modules/cart_new/api.js'
import NoNetworkEmpty from 'public/src/pages/cart_v2/components/offline/NoNetworkEmpty.vue'
import FilterLabels from '../comps/FilterLabels.vue'
import ResetAllFilter from '../comps/ResetAllFilter.vue'
import { usePitProduct } from '../hooks/usePitProduct.js'
import { PitProduct } from '../utils/PitProduct.js'
import { getQuickAddCartInstance, canUseNewQuickAdd, plugins } from 'public/src/pages/components/product/quick_add_v3/common'
import { DirectlyAddCartPlugin, validOneSkc, validOneSku, validCustomization } from '@shein-aidc/bs-quick-add-cart-standard-mobile-vue2'

const initParams = (params) => {
  return {
    pageNum: 1,
    sort: 0,
    ...params,
  }
}

export default {
  name: 'AddOnItemV2',
  setup() {
    const getPitType = (feedsType) => {
      let type = ''
      if (feedsType === 'on_A') type = 'SINGLE_CARD'
      if (feedsType === 'on_B') type = 'MULTI_CARD'
      return type
    }
    const { getPitProductsInfo } = usePitProduct()
    return {
      getPitProductsInfo,
      getPitType,
    }
  },
  components: {
    DrawerContent: Content,
    FooterTips,
    FilterBar,
    ProductListV3: ListV3,
    NoNetworkEmpty,
    FilterLabels,
    ResetAllFilter,
  },
  mixins: [fullScreenMixin],
  props: {
    page: Boolean,
    type: {
      type: String,
      required: true,
    },
    drawerConfig: {
      type: Object,
      default: () => ({}),
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    queryInfo: {
      type: Object,
      default: () => ({
        addOnType: '',
        sceneId: '',
        goodsIds: [],
        cateIds: [],
        mallCode: '',
        goodsPrice: '',
        direct_tag: [],
        return_tag: [],
        adp: [],
        mainGoodsIds: [],
      })
    },
    storeInfo: {
      type: Object,
      default: () => ({})
    },
    saInfo: {
      type: Object,
      default: () => ({})
    },
    abtInfo: {
      type: Object,
      default: () => ({})
    },
    flashsaleIconStyle: {
      type: Object,
      default: () => ({})
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    mainCode: {
      type: String,
      default: '',
    },
    footerType: {
      type: String,
      default: 'normal', // normal | business-cart
    },
    feedsType: {
      type: String,
      default: 'off', // off | on_A | on_B
    },
    getBusinessCartFoot: {
      type: Function,
      default: null,
    }
  },
  data() {
    return {
      ADD_ON_COMP_MAP,
      isClickPitProduct: false,
      quickAddInstance: null,
      loading: false,
      queryParams: initParams(),
      products: [],
      total: 0,
      loadMoreFlag: false,
      filterData: {
        categories: [],
        attrs: [],
        maxPrice: 0,
        minPrice: 0,
      },
      pitProduct: null, // 插坑商品

      isOnline: true,
      selectedFilterLabels: [],
      initHaveStoreFilter: false,
    }
  },
  computed: {
    ...mapState(['cardConfig']),
    commonParams() {
      const { 
        addOnType, 
        sceneId, 
        cateIds, 
        goodsIds, 
        goodsPrice, 
        mallCode, 
        includeTspId, 
        excludeTspId,
        quickship_prior,
        jsonRuleId,
        location,
        adp,
        mainGoodsIds,
      } = this.queryInfo

      return {
        goodsIds: [...new Set(goodsIds)].join(','), // 商品ID,  
        cateIds: [...new Set(cateIds)].join(','), // 类目ID
        jsonRuleId, // 实验参数
        location: location || 'addnewhotJson',
        goods_price: goodsPrice || '0', // 凑单差额价格(美分)
        mall_code: mallCode,
        scene_id: sceneId,
        add_on_type: addOnType,
        include_tsp_id: includeTspId,
        exclude_tsp_id: excludeTspId,
        quickship_prior,
        adp, // TR-27530支持外围传入指定商品id
        main_goods_id: [...new Set(mainGoodsIds)].slice(0, 20).join(','),
        shop_id: this.queryParams?.shop_id
      }
    },
    sa() {
      return {
        ...SA_INFO,
        ...this.saInfo,
      }
    },
    itemConfigV3() { // 商卡配置
      return {
        itemDAEventExposeId: '2-3-2',
        ...this.cardConfig?.cardConfig,

        // 凑单场景特殊参数
        disableApartFromTheDetailsJump: true,
        showDeliveryWords: false, // 凑单弹窗不接入趋势
        showEstimatedPriceOnSale: false, // 凑单弹窗不接入到手价
        disableMainimgJump: !this.config?.isClickToDetail,
        style: {
          // 免邮凑单弹窗，优惠券凑单弹窗，加购按钮配置成 ADD 文字
          addBagBtnStyle: ['freeShipping', 'coupon'].includes(this.type) && ['checkout_shipping_add', 'checkout_shipping_coupon_add'].includes(this.saInfo?.activity_from) ?  'AddText' : 'default'
        },
        urlQuery: {
          mainCode: this.mainCode
        },

      }
    },
    showRestAll() {
      if(!this.products.length > 0) return false
      if(this.selectedFilterLabels.length > 0) return true
      const keys = Object.keys(this.queryParams)
      for(let key of keys) {
        if(key == 'pageNum' || key == 'sort') continue
        let val = this.queryParams[key]
        if(Array.isArray(val) && val.length > 0) return true
        if(typeof val != 'object' && val) return true
        if(typeof val == 'object' && Object.keys(val).length > 0) return true
      }
      return false
    },
    showFilterLabelsChecked() {
      if(this.storeInfo.id && this.initHaveStoreFilter && this.selectedFilterLabels.length > 0) return true
      else return false
    },
    showFilterLabelsUnchecked() {
      if(this.storeInfo.id && this.initHaveStoreFilter && this.selectedFilterLabels.length == 0) return true
      else return false
    },
    hasBusinessCart() {
      return this.footerType === 'business-cart'
    },
  },
  mounted() {
    this.initData()

    EventSetProgressPromise.subscribe({
      callback: () => {
        this.setPromiseCartUpdate()
        this.onAddToBagSuccess()
      }
    })

    EventListReset.subscribe({
      callback: (conf) => {
        if (conf?.reset) {
          // conf?.type == 'changeCoupon'
          this.refreshData(conf?.type == 'changeCoupon')
        } else {
          this.initData()
        }
      }
    })

    this.$nextTick(() => {
      if (this.page) return
      this.unregister()
      if (this.saInfo?.activity_from !== 'coupon_add') {
        this.register()
      }
    })
  },
  destroyed() {
    this.quickAddInstance?.$off('addToBagSuccess')
  },
  methods: {
    ...mapMutations(['setProgressDataPromise']),
    async initData() {
      try {
        this.loading = true
        this.fetchFilter(true) // 请求筛选项
        await this.getListData(true) // 请求列表数据 
        
      } finally {
        this.loading = false
      }
    },
    async fetchFilter(isFirst) {
      let extraParams = {}
      if(isFirst && this.storeInfo?.id) {
        extraParams.shop_id = this.storeInfo.id
      }
      const [r1, r2] = await Promise.all([this.getFilterData(extraParams), this.getAttrData(extraParams)])
      this.filterData = { ...r1, ...r2 }
    },
    
    handleExpose({ code, total }) {
      let service_error
      if (code == '0' && total == 0) {
        service_error = 'goods_is_empty'
      } else if (code != '0') {
        service_error = code ? code : 'recommend_unknown_error'
      }
      EventExposeAddCart.notify({ service_error })
      EventPageAnalysis.notify({ service_error })
    },
    async getFilterData(extraParams = {}) {
      const params = {
        ...this.commonParams,
        ...extraParams,
        resultType: 'category'
      }
      let res = await getListsV3(params)

      const { extra_data: { max_price = 20, min_price = 0, categories } } = res?.info || {}
      return {
        categories: fotmatCategories(categories),
        minPrice: min_price,
        maxPrice: max_price,
      }
    },
    async getAttrData(extraParams = {}) {
      const params = {
        ...this.commonParams,
        ...extraParams,
        resultType: 'attr_filter'
      }
      let res = await getListsV3(params)

      const { extra_data: { attrs = [] } } = res?.info || {}
      return { attrs }
    },
 
    async getListData(isFirst, tryStore = false) {
      const params = {
        ...this.commonParams,
        stock_enough: 1, // 是否有库存
        pageEnable: 1,
        limit: 20, // 每页数量 不要改
        req_num: 300,
        ...this.queryParams,
      }

      if(this.storeInfo?.id && (isFirst || tryStore)) {
        params.shop_id = this.storeInfo.id
      }

      let res, pitRes, products, total, code
      const task = []
      task.push(getInterfaceListV3(params, {
        cardConfig: this.cardConfig,
        abtInfo: this.abtInfo,
        itemConfig: this.itemConfigV3,
      }))
      if (isFirst && this.hasBusinessCart && this.feedsType && this.feedsType !== 'off') {
        task.push(this.getPitProductsInfo({ reset: true }))
      }
      ;[res, pitRes] = await Promise.all(task)

      if (pitRes && !this.pitProduct) {
        
        this.pitProduct = new PitProduct({ type: this.getPitType(this.feedsType) })
        await this.pitProduct.initialize(pitRes)
      }

      if(params.shop_id && res.products?.length && (isFirst || tryStore)) {
        this.initHaveStoreFilter = true
        if(!this.selectedFilterLabels?.includes(LabelKeyMap.STORE)) {
          this.selectedFilterLabels?.push(LabelKeyMap.STORE)
          this.changeFilter({ shop_id: this.storeInfo.id }) // for after refresh
        }
      }
      if(this.storeInfo?.id && !res.products?.length && (isFirst || tryStore)) {
        delete params.shop_id
        this.fetchFilter()
        res = await getInterfaceListV3(params, {
          cardConfig: this.cardConfig,
          abtInfo: this.abtInfo,
          itemConfig: this.itemConfigV3,
        })
      }

      products = res.products
      if (this.pitProduct && !this.handleHasFilterData()) {
        products = this.getProductsAfterPit(products)
      }

      total = res.total
      code = res.code

      let productList = []
      if (this.queryParams.pageNum > 1) {
        productList = [...this.products, ...products]
      } else {
        productList = products
      }
      this.products = productList
      this.total = total

      // 弹窗首次请求需要曝光
      if (isFirst) {
        this.handleExpose({ total, code })
      }
      return code
    },

    // 获取插坑后的商品列表
    getProductsAfterPit(products) {
      const resultArray = this.pitProduct.getProductsByPit(products)
      return resultArray
    },

    changeFilter(data) {
      this.queryParams = Object.assign(this.queryParams, data)
    },

    async filterChange(params, isReset) {
      EventViewTap.notify({ type: 'FILTER_CHANGE' })

      if (isReset) {
        this.reset()
      } else {
        this.changeFilter({ pageNum: 1, ...params })
      }

      this.$el.querySelector('.j-drawer-content__list')?.scrollTo(0, 0)
      this.loadMoreFlag = false

      const code = await this.getListData()
      return code
    },

    reset() {
      this.queryParams = initParams() // 参数rest
      this.$refs.filter?.resetFilter()
      this.selectedFilterLabels = []
    },

    async loadMore() {
      if (this.isClickPitProduct) {
        this.isClickPitProduct = false
      } else {
        this.pitProduct?.stop()
      }
      if (this.products.length >= this.total || this.loadMoreFlag) return
      this.loadMoreFlag = true
      this.changeFilter({ pageNum: this.queryParams.pageNum + 1 })
      await this.getListData()
      this.loadMoreFlag = false
    },

    onFilterBar() {},

    async onAddToBagSuccess({ target } = {}) {
      const { bubbleText, isChange } = await this.progressDataPromise.promise
      
      EventUpdateIncentivePoint.notify({
        showBubble: !bubbleText,
      })

      // 需要等到 必要的接口数据返回(progressDataPromise) 才执行以下代码
      let cartBtnEle
      let progressEle
      if (target) {
        cartBtnEle = target.querySelector('.j-icon-addtobag')
        // 如果有非标购物袋优先商品掉落终点为购物袋，没有则掉落终点为进度条
        progressEle = document.querySelector('.j-add-on-container .j-add-on-business-cart') || document.querySelector('.j-add-on-container .j-progress-bar')
      }
      if (!cartBtnEle || !progressEle) {
        this.$refs.footerTips.start({ bubbleText }) // 气泡
        EventProgressUpdate.notify() // 进度条
        // 免邮活动切换成另一场时，需要刷新列表
        if (isChange) {
          this.refreshData()
        }

        this.$emit('addBagAnimateFinish')
        return
      }
      // 获取当前购物车按钮相对于屏幕的位置
      const rectByCart = cartBtnEle.getBoundingClientRect()
      // 获取进度条相对于屏幕的位置
      const rectByProgress = progressEle.getBoundingClientRect()
      // 复制一份 flashsale icon，并添加到 body 中
      const flashsaleClonedEle = document.querySelector('.j-icon-flashsale-wrapper').cloneNode(true)
      flashsaleClonedEle.id = 'j-icon-flashsale-wrapper'
      flashsaleClonedEle.style.position = 'fixed'
      flashsaleClonedEle.style.zIndex = 999999
      flashsaleClonedEle.style.left = `${rectByCart.left}px`
      flashsaleClonedEle.style.top = `${rectByCart.top}px`
      flashsaleClonedEle.style.display = 'flex'
      document.body.appendChild(flashsaleClonedEle)

      // 执行动画
      parabolaAnimate(
        'j-icon-flashsale-wrapper',
        {
          x: rectByCart.left,
          y: rectByCart.top,
        },
        {
          x: rectByProgress.left,
          y: rectByProgress.top,
        },
        () => {
          flashsaleClonedEle.remove()
          this.$refs?.footerTips?.start({ bubbleText }) // 气泡
          EventProgressUpdate.notify() // 进度条
          // 免邮活动切换成另一场时，需要刷新列表
          if (isChange) {
            this.refreshData()
          }

          this.$emit('addBagAnimateFinish')
        }
      )
    },
    addCartRule({ emit, data }) {
      return emit === 'open-quick-add' && validOneSkc(data) && validOneSku(data) && !validCustomization(data)
    },
    directlyAddCartCallBack({ data = {}, target } = {}) {
      if (data?.code == '0') {
        this.setPromiseCartUpdate()
        this.$emit('update-cart') // 更新券信息
        EventUpdateCart.notify()
        this.onAddToBagSuccess({ target })
      } else {
        this.handleAddToBagFail(data)
      }
    },
    async handleAddtobag({ emit, item = {}, target, index, imgRatio = '3-4' }) {
      let addCartRule = ''
      if (emit === 'open-quick-add') {
        addCartRule = 3
      }
      EventViewTap.notify({ type: 'ADD_TO_BAG' })
      if (canUseNewQuickAdd('details')) {
        const pluginArr = [DirectlyAddCartPlugin((data) => this.addCartRule({ emit, data }), (data) => this.directlyAddCartCallBack({ data, target }))]
        if (this.config.isBuyNow) {
          const buyNowData = {
            checkout_no: this.config.casualCheckoutNo ?? '', // 结算号
            needJump: '', // 是否需要跳转到订单
            autoGetCoupon: false, // 是否自定领取优惠券
            autoUpdateCouponList: false, // 是否自动更新优惠价列表
          }
          pluginArr.push(plugins.buyNowPlugin(buyNowData))
        }
        // 关闭其他加车弹窗
        getQuickAddCartInstance()?.close()
        getQuickAddCartInstance().open(
          {
            goods_id: item.goods_id,
          },
          {
            sourceIdentifier: getAddSourceByType(this.type, this.saInfo),
            plugins: pluginArr,
            excludePlugins: this.config.excludePlugins || [],
            analysisConfig: {
              index,
              sourceTarget: target,
              code: 'cartAddItem',
              from: 'cart',
              sa: this.sa,
            },
            extendedParam: {
              imgRatio,
              add_source: getAddSourceByType(this.type, this.saInfo)
            },
            featureConfig: {
              needClickToDetail: !!this.config?.isClickToDetail, // 商详跳转
            },
            callbacks: {
              onJumpDetail: (data) => {
                if (!this.config?.isClickToDetail) return
                const { rawUrl } = data || {}
                if (rawUrl) {
                  const url = this.mainCode ? rawUrl + '&' + stringifyQueryString({ queryObj: { mainCode: this.mainCode } }) : rawUrl
                  routerPush(url)
                }
              },
              onAddCartComplete: (data) => {
                if (data?.isSuccess) {
                  this.setPromiseCartUpdate()
                  
                  this.$emit('update-cart') // 更新券信息
                  EventUpdateCart.notify()
                  this.onAddToBagSuccess({ target })
                }
              },
            }
          }
        )
      } else {
        this.quickAddInstance?.$off('addToBagSuccess')
        this.quickAddInstance = await this.$quickAdd.open({
          goods_id: item.goods_id,
          index,
          imgRatio,
          mainCode: this.mainCode,
          addCartRule,
          isClickToDetail: this.config?.isClickToDetail, // 商详跳转
          customSubmitParam: {
          // 页面来源
            add_source: getAddSourceByType(this.type, this.saInfo)
          },
          clickCallBack: {
            addQuickComplete: (res) => {
              const { data, target } = res
              if (data?.code == '0') {
                this.setPromiseCartUpdate()
                this.$emit('update-cart') // 更新券信息
                EventUpdateCart.notify(data.info)
                this.onAddToBagSuccess({ target })
              } else {
                this.handleAddToBagFail(data)
              }
            },
            cart: (data) => {
              if (data?.code == '0') {
                this.setPromiseCartUpdate()

                this.$emit('update-cart') // 更新券信息
                EventUpdateCart.notify(data.info)
              }
            },
            detail: () => {
              EventClose.notify()
            }
          },
          analysisConfig: {
            sourceTarget: target,
            code: 'cartAddItem',
            from: 'cart',
            sa: this.sa,
          },
          isAddOnItem: true,
          isBuyNow: this.config.isBuyNow,
          casualCheckoutNo: this.config.casualCheckoutNo ?? '',
        })

        //  加车成功并收起弹窗
        this.quickAddInstance?.$on('addToBagSuccess', this.onAddToBagSuccess)
      }
    },
    async handleUpdateBag({ target, index, imgRatio = '3-4' }, cartItem) {
      this.isClickPitProduct = true

      EventViewTap.notify({ type: 'ADD_TO_BAG' })
      const selectedAttrIndex = {}
      if(cartItem.attrs) {
        const attrs = JSON.parse(cartItem.attrs)
        attrs.forEach(item => {
          selectedAttrIndex[item.attr_id] = { attr_value_id: item.attr_value_id }
        })
      }
      if (cartItem.skuSaleAttr?.length) {
        cartItem.skuSaleAttr.forEach(item => {
          if(!item.is_main){
            selectedAttrIndex[item.attr_id] = { attr_value_id: item.attr_value_id }
          }
        })
      }

      if (canUseNewQuickAdd('cart')) {
        getQuickAddCartInstance().open(
          {
            goods_id: cartItem.goodsId,
            mallCode: cartItem.mallCode,
          },
          {
            sourceIdentifier: getAddSourceByType(this.type, this.saInfo),
            plugins: [plugins.salesAttrUpdatePlugin(async context => {
              const reqParams = {
                is_checked: '1',
                sku_code: context?.skuInfo?.sku_code,
                id: cartItem.cartId,
                mall_code: context?.mallCode,
                quantity: context?.quantity,
                goods_id: context?.productInfo?.goods_id,
              }
              const res = await cartUpdateAttr(reqParams)
              daEventCenter.triggerNotice({
                daId: '1-8-1-19',
                extraData: {
                  update_result: res.code === '0' ? 1 : 0,
                  sku_id: cartItem.goodsSn,
                  mall_code: cartItem.mallCode,
                  saExtendedParams: {
                    scene: 'cart_additems_in_feeds',
                  },
                }
              })
              if (res?.code == '0') {
                this.setPromiseCartUpdate()
                this.$emit('update-cart') // 更新券信息
                EventUpdateCart.notify()
                this.onAddToBagSuccess({ target })
              } else {
                this.$toast(res?.msg || '')
              }
            })],
            excludePlugins: this.config.excludePlugins || [],
            analysisConfig: {
              index,
              sourceTarget: target,
              code: 'cartAddItem',
              from: 'cart',
              sa: this.sa,
            },
            extendedParam: {
              imgRatio,
              quantity: cartItem.quantity,
              add_source: getAddSourceByType(this.type, this.saInfo)
            },
            featureConfig: {
              needClickToDetail: !!this.config?.isClickToDetail, // 商详跳转
              footerBar: {
                // 是否需要收藏按钮
                needFavoriteButton: false
              },
            },
            salesAttrConfig: {
              selectedAttrIndex,
            },
          }
        )
      } else {
        this.quickAddInstance?.$off('addToBagSuccess')
        this.quickAddInstance = await this.$quickAdd.open({
          goods_id: cartItem.goodsId,
          index,
          imgRatio,
          mallCode: cartItem.mallCode,
          isClickToDetail: this.config?.isClickToDetail, // 商详跳转
          selectedAttrIndex,
          item_id: cartItem.cartId,
          quantity: cartItem.quantity,
          isShowEdit: true,
          isShowWishBtn: false,
          showPromotionTagInfo: false,
          isAddOnItem: true,
          customSubmitParam: {
          // 页面来源
            add_source: getAddSourceByType(this.type, this.saInfo)
          },
          clickCallBack: {
            edit: async ({ res }) => {
              if (res.code == 0) {
                await modifyCartCheckStatus({
                  operation_type: 1,
                  cart_id_list: [res.info.cart.id],
                })

                this.$toast(this.language.SHEIN_KEY_PWA_15060)
              
                this.setPromiseCartUpdate()
                this.$emit('update-cart') // 更新券信息
                EventUpdateCart.notify(res.info.info)
                this.onAddToBagSuccess({ target })
              } else if (res.code == 300402) {
                this.$toast(this.language.SHEIN_KEY_PWA_15230)
              } else if ([200401, 500301, 500302, 500306, 300417, 302444, 300714].includes(+res.code)) {
                let errTxt = this.language.SHEIN_KEY_PWA_14967
                if (res.info && res.info.limitCount) {
                  errTxt = this.language.SHEIN_KEY_PWA_16138.replace('{0}', res.info.limitCount)
                }
                if ([300714, 500302, 500306, 302444, 300417].includes(+res.code)) {
                  errTxt = res.tips.replace('{0}', res?.info?.limitCount)
                }
                if ([500302, 500306, 300417].includes(+res.code)) {
                  errTxt = template(
                    res?.info?.limitCount,
                    res?.info?.resultLimit,
                    res?.info?.remainNum,
                    res.tips
                  )
                  this.$toast(errTxt, 4000)
                } else {
                  this.$toast(errTxt, 4000)
                }
              } else if (res.code == 500303) {
                const errTxt = template(
                  res?.info?.limitCount,
                  res?.info?.resultLimit,
                  res?.info?.remainNum,
                  res.tips
                )
                this.$toast(errTxt, 4000)
                // } else if (res.code == 500304 || res.code == 500305) {
              // ...
              } else if (res.code == 300405) {
                this.$toast(res.info.limit_desc.replace('N', res.info.limit_count))
              } else {
                this.$toast(res.tips)
              }
            },
            customizeAnalyticsEvent: ({ daId, extraData }) => {
              if (daId === '1-8-1-19') {
                return {
                  ...extraData,
                  saExtendedParams: {
                    scene: 'cart_additems_in_feeds',
                  },
                }
              }
              return extraData
            },
          },
          analysisConfig: {
            sourceTarget: target,
            code: 'cartAddItem',
            from: 'cart',
            sa: this.sa,
          },
        })

        //  加车成功并收起弹窗
        this.quickAddInstance?.$on('addToBagSuccess', this.onAddToBagSuccess)
      }
      
    },
    handleClickPitItem(product) {
      if (this.footerType !== 'business-cart') return
      const businessCart = this.getBusinessCartFoot?.()
      if (!businessCart) return
      businessCart.openBusinessCart?.({
        feedsAddItems: 2,

        id: product.cartId,
        highlightAnimate: true,
        clickFrom: 'cart_add_cell',
      })
    },
    handleClickPitCard(item) {
      this.handleClickPitItem(item.items[0])
    },

    setPromiseCartUpdate() {
      this.progressDataPromise = new PromiseActuator()
      this.setProgressDataPromise(this.progressDataPromise)
    },

    handleResetList() {
      this.filterChange({}, true)
    },
    // 刷新推荐列表的数据
    async refreshData(tryStore = false) {
      try {
        this.$el.querySelector('.drawer-content__list')?.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
        this.products = []
        this.loading = true
        this.loadMoreFlag = false
        this.reset()
        this.fetchFilter() // 请求筛选项
        if(this.initHaveStoreFilter && tryStore) this.initHaveStoreFilter = false
        await this.getListData(false, tryStore) // 请求列表数据
      } finally {
        this.loading = false
      }
    },
    handleOnlineStatus(val) {
      this.isOnline = val
    },
    async handleFilterChange(type, label) {
      let selectedStatus
      if(type == 'check') {
        const cacheParams = {
          pageNum: this.queryParams.pageNum,
          shop_id: this.queryParams.shop_id,
        }
        let filterParams = {
          pageNum: 1,
        }
        if(label == LabelKeyMap.STORE) {
          filterParams.shop_id = this.storeInfo?.id
        }
        const code = await this.filterChange(filterParams)
        if(code == 0) {
          this.selectedFilterLabels.push(label)
          selectedStatus = 1
        } else {
          selectedStatus = 0
          this.changeFilter(cacheParams)
        }
      } else if(type == 'cancel') {
        const index = this.selectedFilterLabels.findIndex(i => i == label)
        if(index > -1) {
          const cacheParams = {
            pageNum: this.queryParams.pageNum,
            shop_id: this.queryParams.shop_id,
          }
          let filterParams = {
            pageNum: 1,
          }
          if(this.queryParams.shop_id) delete this.queryParams.shop_id
          const code = await this.filterChange(filterParams)
          if(code == 0) {
            this.selectedFilterLabels.splice(index, 1)
            selectedStatus = 0
          } else {
            selectedStatus = 1
            this.changeFilter(cacheParams)
          }
        } else selectedStatus = 0
      }
      daEventCenter.triggerNotice({
        daId: '1-8-4-27',
        extraData: {
          store_code: this.storeInfo.id,
          is_chosen: selectedStatus,
        }
      })
    },
    async handleResetFilter() {
      const cacheParams = this.queryParams
      const code = await this.filterChange({}, true)
      if(code == 0) this.selectedFilterLabels = []
      else this.changeFilter(cacheParams) // query failed then recover params
    },
    handleAddToBagFail(data) {
      let { code, info, tips } = data

      if (['300410'].includes(code)) {
        return
      } else if (['500304', '500305'].includes(code)) {
        const type = code == '500304' ? 1 : 2
        if (type == 1) {
          this.$toast(this.language.SHEIN_KEY_PWA_17323)
        }
      } else {
        let addToBagFailMsg = ''
        if (code == '300402') {
          addToBagFailMsg = this.language.SHEIN_KEY_PWA_24651
        } else if (['200401', '500301'].includes(code)) {
          addToBagFailMsg = this.language.SHEIN_KEY_PWA_14967
        } else if (['500302', '500306'].includes(code)) {
          addToBagFailMsg = this.flashSaleLimitTip(data)
        } else if (code == '500303') {
          addToBagFailMsg = this.template(
            info?.limitCount || '',
            info?.resultLimit || 0,
            info?.remainNum || 0,
            tips
          )
        } else if (code === '300417') {
          addToBagFailMsg = tips
            ? this.template(
              info?.limitCount || 1,
              info?.resultLimit || 0,
              info?.remainNum || 0,
              tips
            )
            : this.language.SHEIN_KEY_PWA_15861
        } else if (['300714', '302444'].includes(code)) {
          addToBagFailMsg = this.template(info?.limitCount || 1, tips)
        } else if (code == '300407') {
          addToBagFailMsg = tips
        } else {
          addToBagFailMsg = tips || this.language.SHEIN_KEY_PWA_15282
        }
        if (addToBagFailMsg) this.$toast({ htmlString: addToBagFailMsg })
      }
    },
    /**
     * 加车页 闪购限制处理
     */
    flashSaleLimitTip(data, tip) {
      const { code, info } = data
      return (Number(code) === 500302 || Number(code) === 500306)
        ? this.htmlDecode({
          text: this.language.SHEIN_KEY_PWA_16138
        }).replace(
          '{0}',
          info?.limitCount || 0
        )
        : tip || this.language.SHEIN_KEY_PWA_15861
    },
    handleHasFilterData() {
      const hasShop = !!this.queryParams.shop_id
      const hasPrice = !!(this.queryParams.min_price || this.queryParams.max_price)
      const hasSort = !!this.queryParams.sort
      const hasAttr = !!this.queryParams.attr_filter
      const hasCate = !!this.queryParams.filter_cate_id
      return hasShop || hasPrice || hasSort || hasAttr || hasCate
    },
    template,
    htmlDecode,
  },
}
</script>

<style lang="less" scoped>
.icon-flashsale-wrapper {
  display: none;
  justify-content: center;
  align-items: center;
  background: var(--bg, #FA6338);
  width: .5333rem;
  height: .5333rem;
  border-radius: 50%;
}
.filter-with-store ::v-deep(.filter-ctn__nav-bar) {
  border-bottom: unset;
}
</style>
