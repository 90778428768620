var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progress-bar j-progress-bar",style:(_vm.style)},[_c('div',{ref:"progressBar",staticClass:"progress-bar-bg",class:{
      'is-animation': _vm.animation,
      'is-transparent': _vm.transparent,
    }}),_vm._v(" "),(_vm.showCircle)?_vm._l((_vm.thresholds),function(item,index){return _c('CircleIcon',{key:index,staticClass:"ico",style:({
        [_vm.GB_cssRight ? 'left' : 'right']: `${100/_vm.thresholds.length * (_vm.thresholds.length - index - 1)}%`
      }),attrs:{"complete":_vm.isComplete(item),"opacity":_vm.isOpacity(index),"size":_vm.circleSize,"icon-style":{
        iconColor: _vm.barStyle.iconColor,
      }}})}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }